import React from 'react';
import SupportPageComponent from '../../../components/Apps/GenericSupportPage';

const TrackMyShoeSupportPage = () => {
  const faqs = [
    {
      question: "I can't see any workouts even though I see them in the Fitness app.",
      answer: "Ensure you have given Track My Shoe permission to access your Health data. Go to Health app > Browse > Profile > Apps (under Privacy). Scroll down to find Track My Shoe, and enable all the permissions.",
      screenshots: [
        "/projects/trackmyshoe/faq/question1-1.png",
        "/projects/trackmyshoe/faq/question1-2.png",
        "/projects/trackmyshoe/faq/question1-3.png",
        "/projects/trackmyshoe/faq/question1-4.png"
      ]
    },
    {
      question: "I don't receive notifications.",
      answer: "Check your device settings to make sure Track My Shoe has permission to send notifications. Go to Settings > Notifications > Track My Shoe and enable alerts.",
      screenshots: [
        "/projects/trackmyshoe/faq/question2-1.png",
        "/projects/trackmyshoe/faq/question2-2.png",
        "/projects/trackmyshoe/faq/question2-3.png"
      ]
    },
    {
      question: "Can I track multiple shoes in the app?",
      answer: "Yes! You can add multiple shoes to Track My Shoe and switch between them as you use different pairs for various activities. This helps in monitoring the wear and tear on each pair separately.",
      screenshots: []
    },
    {
      question: "What should I do if Track My Shoe is crashing or freezing?",
      answer: "Try restarting your phone. If the issue persists, ensure you have the latest version of the app installed. If the problem continues, contact our support team with the details of the issue so we can assist further.",
      screenshots: []
    },
  ];

  return (
      <SupportPageComponent
        projectName="Track My Shoe"
        title="Support for Track My Shoe"
        description={[
          "Track your running and walking shoes' mileage to know when it's time for a replacement. With Track My Shoe, you can easily assign shoes to your workouts and track how far you've gone. Never let your shoes reach their limit without knowing it!",
          "Sync your workouts automatically from the Health app, and get notified when it's time to assign shoes for your walk or run. Monitor your shoe's progress and stay safe with optimal footwear. Start tracking your shoes today!"
        ]}
        imageUrl={process.env.PUBLIC_URL + "/projects/trackmyshoe/trackmyshoe-thumbnail.png"}
        faqQuestions={faqs}
      />
  );
};

export default TrackMyShoeSupportPage;