import React, { useState } from "react";
import { ListItemButton, ListItemIcon, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FAQQuestion = ({ question, answer, screenshots }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    return (
      <div style={{ marginBottom: "1.5rem", borderBottom: "1px solid #ddd", paddingBottom: "1rem" }}>
        <ListItemButton onClick={() => setIsExpanded(!isExpanded)} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
          <ListItemIcon>
              <ArrowDropDownIcon
                style={{
                  transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transition: 'transform 0.3s',
                  fontSize: '1.5rem'
                }}
              />
          </ListItemIcon>
          <Typography
            variant="body1"
            style={{
              fontWeight: 'bold',
              color: '#000',
              marginLeft: '-30px',
            }}
          >
            {question}
          </Typography>
        </ListItemButton>
        {isExpanded && (
          <div style={{ marginTop: "0.5rem" }}>
            <p style={{ fontSize: "0.9rem", color: "#333" }}>{answer}</p>
            {screenshots && (
              <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                {screenshots.map((url, index) => (
                  <img key={index} src={url} alt={`screenshot-${index}`} style={{ maxWidth: "20%", borderRadius: "5px" }} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  export default FAQQuestion;